import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { sanitizeInput } from '../utils/sanitize'

interface GameDetails {
  _id: string
  title: string
  dateTimeOptions: Date[]
  location: string
  gameType: string
  organizerId: string
  organizer: {
    username: string
  }
}

interface Invitation {
  _id: string
  gameId: string
  inviterId: string
  inviteeEmail: string
  status: 'pending' | 'accepted' | 'declined' | 'suggested_alternative'
  availability: Date[]
  suggestedDates: Date[]
  token: string
}

// Mock data
const mockInvitation: Invitation = {
  _id: '123456',
  gameId: '789012',
  inviterId: '345678',
  inviteeEmail: 'invitee@example.com',
  status: 'pending',
  availability: [],
  suggestedDates: [],
  token: 'mock-token-123'
}

const mockGameDetails: GameDetails = {
  _id: '789012',
  title: 'Weekend Golf Tournament',
  dateTimeOptions: [
    new Date('2023-06-10T09:00:00'),
    new Date('2023-06-11T10:00:00'),
    new Date('2023-06-17T09:00:00')
  ],
  location: 'Sunny Hills Golf Club',
  gameType: 'Tournament',
  organizerId: '345678',
  organizer: {
    username: 'GolfPro'
  }
}

const InvitationResponse: React.FC = () => {
  const [invitation, setInvitation] = useState<Invitation | null>(null)
  const [gameDetails, setGameDetails] = useState<GameDetails | null>(null)
  const [selectedDates, setSelectedDates] = useState<string[]>([])
  const [suggestedDates, setSuggestedDates] = useState<string[]>([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string | null>(null)
  const navigate = useNavigate()

  useEffect(() => {
    // Simulate API call with setTimeout
    setTimeout(() => {
      setInvitation(mockInvitation)
      setGameDetails(mockGameDetails)
      setLoading(false)
    }, 1000)
  }, [])

  const handleDateSelection = (date: string) => {
    setSelectedDates((prev) =>
      prev.includes(date) ? prev.filter((d) => d !== date) : [...prev, sanitizeInput(date)]
    )
  }

  const handleSuggestedDateChange = (index: number, value: string) => {
    setSuggestedDates((prev) => {
      const newDates = [...prev]
      newDates[index] = sanitizeInput(value)
      return newDates
    })
  }

  const addSuggestedDate = () => {
    setSuggestedDates((prev) => [...prev, ''])
  }

  const removeSuggestedDate = (index: number) => {
    setSuggestedDates((prev) => prev.filter((_, i) => i !== index))
  }

  const handleAccept = async () => {
    if (!invitation || selectedDates.length === 0) return
    console.log('Accepting invitation:', { invitationId: invitation._id, selectedDates })
    navigate('/dashboard', { state: { message: 'Invitation accepted successfully!' } })
  }

  const handleDecline = async () => {
    if (!invitation) return
    console.log('Declining invitation:', { invitationId: invitation._id })
    navigate('/dashboard', { state: { message: 'Invitation declined successfully!' } })
  }

  const handleSuggestAlternative = async () => {
    if (!invitation || suggestedDates.length === 0) return
    console.log('Suggesting alternative dates:', { invitationId: invitation._id, suggestedDates })
    navigate('/dashboard', { state: { message: 'Alternative dates suggested successfully!' } })
  }

  if (loading) return <div className="text-center mt-8">Loading...</div>
  if (error) return <div className="text-center mt-8 text-red-500">{error}</div>
  if (!invitation || !gameDetails)
    return <div className="text-center mt-8">No invitation details found</div>

  return (
    <div className="max-w-lg mx-auto mt-8 p-6 bg-white rounded-lg shadow-md">
      <h2 className="text-2xl font-bold mb-4">Game Invitation</h2>
      <div className="mb-4">
        <p>
          <strong>Title:</strong> {gameDetails.title}
        </p>
        <p>
          <strong>Organizer:</strong> {gameDetails.organizer.username}
        </p>
        <p>
          <strong>Location:</strong> {gameDetails.location}
        </p>
        <p>
          <strong>Game Type:</strong> {gameDetails.gameType}
        </p>
      </div>
      <div className="mb-4">
        <p className="font-semibold mb-2">Select Available Dates:</p>
        {gameDetails.dateTimeOptions.map((date, index) => (
          <label key={index} className="flex items-center mb-2">
            <input
              type="checkbox"
              checked={selectedDates.includes(date.toISOString())}
              onChange={() => handleDateSelection(date.toISOString())}
              className="mr-2"
            />
            {new Date(date).toLocaleString()}
          </label>
        ))}
      </div>
      <div className="mb-4">
        <p className="font-semibold mb-2">Suggest Alternative Dates:</p>
        {suggestedDates.map((date, index) => (
          <div key={index} className="flex items-center mb-2">
            <input
              type="datetime-local"
              value={date}
              onChange={(e) => handleSuggestedDateChange(index, e.target.value)}
              className="mr-2 p-2 border rounded"
            />
            <button onClick={() => removeSuggestedDate(index)} className="text-red-500">
              Remove
            </button>
          </div>
        ))}
        <button
          onClick={addSuggestedDate}
          className="mt-2 px-3 py-1 bg-blue-500 text-white rounded"
        >
          Add Suggested Date
        </button>
      </div>
      <div className="flex justify-between">
        <button
          onClick={handleAccept}
          disabled={selectedDates.length === 0}
          className="bg-green-500 text-white px-4 py-2 rounded disabled:bg-gray-300"
        >
          Accept
        </button>
        <button onClick={handleDecline} className="bg-red-500 text-white px-4 py-2 rounded">
          Decline
        </button>
        <button
          onClick={handleSuggestAlternative}
          disabled={suggestedDates.length === 0}
          className="bg-yellow-500 text-white px-4 py-2 rounded disabled:bg-gray-300"
        >
          Suggest Alternative
        </button>
      </div>
    </div>
  )
}

export default InvitationResponse
