import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

interface Game {
  _id: string
  title: string
  dateTimeOptions: Date[]
  confirmedDateTime?: Date
  location: string
  gameType: string
  status: 'pending' | 'confirmed' | 'completed' | 'cancelled'
  participants: string[]
}

interface Invitation {
  _id: string
  gameId: string
  status: 'pending' | 'accepted' | 'declined' | 'suggested_alternative'
  game: Game
}

// Mock data
const mockGames: Game[] = [
  {
    _id: '1',
    title: 'Weekend Golf Tournament',
    dateTimeOptions: [new Date('2023-06-10T09:00:00'), new Date('2023-06-11T10:00:00')],
    location: 'Sunny Hills Golf Club',
    gameType: 'Tournament',
    status: 'pending',
    participants: ['user1', 'user2', 'user3']
  },
  {
    _id: '2',
    title: 'Casual Round with Friends',
    dateTimeOptions: [new Date('2023-06-15T14:00:00')],
    confirmedDateTime: new Date('2023-06-15T14:00:00'),
    location: 'Green Meadows Golf Course',
    gameType: 'Casual Round',
    status: 'confirmed',
    participants: ['user1', 'user4', 'user5']
  }
]

const mockInvitations: Invitation[] = [
  {
    _id: 'inv1',
    gameId: '3',
    status: 'pending',
    game: {
      _id: '3',
      title: 'Charity Golf Event',
      dateTimeOptions: [new Date('2023-07-01T08:00:00')],
      location: 'Royal Golf Club',
      gameType: 'Tournament',
      status: 'pending',
      participants: ['user6', 'user7']
    }
  },
  {
    _id: 'inv2',
    gameId: '4',
    status: 'accepted',
    game: {
      _id: '4',
      title: 'Monthly League Game',
      dateTimeOptions: [new Date('2023-06-20T13:00:00')],
      location: 'Pine Valley Golf Club',
      gameType: 'League',
      status: 'confirmed',
      participants: ['user1', 'user8', 'user9']
    }
  }
]

const DashboardView: React.FC = () => {
  const [myGames, setMyGames] = useState<Game[]>([])
  const [invitations, setInvitations] = useState<Invitation[]>([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    // Simulate API call with setTimeout
    setTimeout(() => {
      setMyGames(mockGames)
      setInvitations(mockInvitations)
      setLoading(false)
    }, 1000)
  }, [])

  if (loading) return <div className="text-center mt-8">Loading...</div>

  return (
    <div className="max-w-4xl mx-auto mt-8 p-6 bg-white rounded-lg shadow-md">
      <h1 className="text-3xl font-bold mb-6">Dashboard</h1>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">My Games</h2>
        {myGames.length === 0 ? (
          <p>You haven't organized any games yet.</p>
        ) : (
          <ul className="space-y-4">
            {myGames.map((game) => (
              <li key={game._id} className="border p-4 rounded-md">
                <Link to={`/games/${game._id}`} className="text-blue-600 hover:underline">
                  <h3 className="text-xl font-medium">{game.title}</h3>
                </Link>
                <p>Status: {game.status}</p>
                <p>Type: {game.gameType}</p>
                <p>Location: {game.location}</p>
                <p>Participants: {game.participants.length}</p>
              </li>
            ))}
          </ul>
        )}
      </section>

      <section>
        <h2 className="text-2xl font-semibold mb-4">Invitations</h2>
        {invitations.length === 0 ? (
          <p>You don't have any invitations.</p>
        ) : (
          <ul className="space-y-4">
            {invitations.map((invitation) => (
              <li key={invitation._id} className="border p-4 rounded-md">
                <Link to={`/games/${invitation.gameId}`} className="text-blue-600 hover:underline">
                  <h3 className="text-xl font-medium">{invitation.game.title}</h3>
                </Link>
                <p>Status: {invitation.status}</p>
                <p>Type: {invitation.game.gameType}</p>
                <p>Location: {invitation.game.location}</p>
                {invitation.status === 'pending' && (
                  <Link
                    to={`/invitations/respond?token=${invitation._id}`}
                    className="mt-2 inline-block bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
                  >
                    Respond to Invitation
                  </Link>
                )}
              </li>
            ))}
          </ul>
        )}
      </section>
    </div>
  )
}

export default DashboardView
