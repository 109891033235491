import React, { useEffect, useState } from 'react'
import { useFeathers } from '../hooks/useFeathers'
import { useUser } from '../contexts/UserContext'
import CreateGameForm from '../components/CreateGameForm'

interface User {
  _id: string
  email: string
  username: string
}

const HomeView: React.FC = () => {
  const [users, setUsers] = useState<User[]>([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string | null>(null)
  const { client } = useFeathers()
  const { role } = useUser()

  useEffect(() => {
    const fetchUsers = async () => {
      if (role === 'admin') {
        try {
          const response = await client.service('users').find()
          setUsers(response.data)
        } catch (error) {
          setError('Error fetching users. Please try again later.')
          console.error('Error:', error)
        } finally {
          setLoading(false)
        }
      } else {
        setLoading(false)
      }
    }

    fetchUsers()
  }, [client, role])

  if (loading) return <div className="text-center text-xl">Loading...</div>
  if (error) return <div className="text-center text-red-500">{error}</div>

  return (
    <div className="w-full mt-10">
      {role === 'admin' && (
        <div className="mt-12">
          <h2 className="text-2xl font-bold text-center mb-4">User List</h2>
          {users.map((user) => (
            <div key={user._id} className="text-center">
              {user.username} ({user.email})
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default React.memo(HomeView)
