import React from 'react';

const AboutView: React.FC = () => {
  return (
    <div>
      <h1>About Boom Golf</h1>
      <p>Boom Golf is an innovative platform for golf enthusiasts.</p>
    </div>
  );
};

export default React.memo(AboutView);