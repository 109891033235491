import React from 'react'
import { Outlet } from 'react-router-dom'
import NavBar from './NavBar'

const Layout = () => {
  return (
    <div className="flex flex-col h-screen bg-yellow-50">
      <header className="bg-white">
        <NavBar />
      </header>
      <main className="flex-grow overflow-auto">
        <div className="container mx-auto px-4 py-6">
          <Outlet />
        </div>
      </main>
      <footer className="text-gray-400 text-xs py-2 text-center">
        <p>©2024 lemons golfware, llc</p>
      </footer>
    </div>
  )
}

export default Layout
