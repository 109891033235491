import React from 'react'
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom'
import { UserProvider, useUser } from './contexts/UserContext'
import Layout from './components/Layout'
import HomeView from './views/HomeView'
import AboutView from './views/AboutView'
import LoginView from './views/LoginView'
import SignupView from './views/SignupView'
import ProfileView from './views/ProfileView'
import AdminView from './views/AdminView'
import InvitationResponse from './components/InvitationResponse'
import DashboardView from './views/DashboardView'
import GameDetailsView from './views/GameDetailsView'

const ProtectedAdminRoute = ({ children }: { children: React.ReactNode }) => {
  const { role } = useUser()
  if (role !== 'admin') {
    return <Navigate to="/" replace />
  }
  return <>{children}</>
}

function App() {
  return (
    <React.StrictMode>
      <UserProvider>
        <Router>
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route index element={<HomeView />} />
              <Route path="about" element={<AboutView />} />
              <Route path="login" element={<LoginView />} />
              <Route path="signup" element={<SignupView />} />
              <Route path="/profile/:username" element={<ProfileView />} />
              <Route
                path="admin"
                element={
                  <ProtectedAdminRoute>
                    <AdminView />
                  </ProtectedAdminRoute>
                }
              />
              <Route path="/invitations/respond" element={<InvitationResponse />} />
              <Route path="/dashboard" element={<DashboardView />} />
              <Route path="/games/:id" element={<GameDetailsView />} />
            </Route>
          </Routes>
        </Router>
      </UserProvider>
    </React.StrictMode>
  )
}

export default App
