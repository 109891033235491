import React from 'react'
import { Link } from 'react-router-dom'
import { useUser } from '../contexts/UserContext'

interface ProfileSidebarProps {
  isOwnProfile: boolean
}

const ProfileSidebar: React.FC<ProfileSidebarProps> = ({ isOwnProfile }) => {
  const { role } = useUser()

  return (
    <div className="p-4">
      <ul className="space-y-2 text-sm">
        {isOwnProfile && (
          <>
            <li>
              <Link to="/change-password" className="text-gray-600 hover:underline">
                Change Password
              </Link>
            </li>
          </>
        )}
        {role === 'admin' && (
          <li>
            <Link to="/admin/users" className="text-gray-600 hover:underline">
              Manage Users
            </Link>
          </li>
        )}
      </ul>
    </div>
  )
}

export default ProfileSidebar
