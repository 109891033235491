import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  ReactNode,
  useCallback
} from 'react'
import { useFeathers } from '../hooks/useFeathers'
import { createClient } from '../feathersClient'

const client = createClient()

type UserRole = 'guest' | 'user' | 'admin'

interface User {
  _id: string
  email: string
  firstName?: string
  lastName?: string
  username: string
  role?: 'user' | 'admin'
  profilePicture?: string
  createdAt?: Date
  updatedAt?: Date
}

interface UserContextType {
  user: User | null
  setUser: (user: User | null) => void
  role: UserRole
  setRole: (role: UserRole) => void
  logout: () => Promise<void>
  login: (email: string, password: string) => Promise<void>
  isLoading: boolean // Add this line
}

const UserContext = createContext<UserContextType | undefined>(undefined)

export const UserProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null)
  const [role, setRole] = useState<UserRole>('guest')
  const [isLoading, setIsLoading] = useState(true) // Add this line
  const { client } = useFeathers()

  useEffect(() => {
    const reAuthenticate = async () => {
      setIsLoading(true) // Add this line
      try {
        const auth = await client.reAuthenticate()
        setUser(auth.user)
        setRole(auth.user.role || 'user')
      } catch (error) {
        console.error('Failed to re-authenticate:', error)
        setUser(null)
        setRole('guest')
      } finally {
        setIsLoading(false) // Add this line
      }
    }

    reAuthenticate()
  }, [client])

  const login = async (email: string, password: string) => {
    setIsLoading(true) // Add this line
    try {
      const auth = await client.authenticate({
        strategy: 'local',
        email,
        password
      })
      setUser(auth.user)
      setRole(auth.user.role || 'user')
    } catch (error) {
      console.error('Login failed:', error)
      throw error
    } finally {
      setIsLoading(false) // Add this line
    }
  }

  const logout = async () => {
    setIsLoading(true) // Add this line
    try {
      await client.logout()
      setUser(null)
      setRole('guest')
    } catch (error) {
      console.error('Logout failed:', error)
    } finally {
      setIsLoading(false) // Add this line
    }
  }

  return (
    <UserContext.Provider value={{ user, setUser, role, setRole, logout, login, isLoading }}>
      {children}
    </UserContext.Provider>
  )
}

export const useUser = () => {
  const context = useContext(UserContext)
  if (context === undefined) {
    throw new Error('useUser must be used within a UserProvider')
  }
  return context
}
