import feathers from '@feathersjs/client'
import socketio from '@feathersjs/socketio-client'
import auth from '@feathersjs/authentication-client'
import io from 'socket.io-client'

const socket = io(process.env.REACT_APP_API_URL || 'http://localhost:3030')

export const createClient = () => {
  const client = feathers()

  client.configure(socketio(socket))
  client.configure(
    auth({
      storage: window.localStorage,
      storageKey: 'feathers-jwt'
    })
  )

  return client
}
