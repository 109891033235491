import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useFeathers } from '../hooks/useFeathers'
import { useUser } from '../contexts/UserContext'

const SignupView = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState<string | null>(null)
  const { client } = useFeathers()
  const navigate = useNavigate()
  const { setUser, setRole } = useUser() // Add setUser here

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    setError(null)
    try {
      console.log('Sending signup data:', { email, password })

      const newUser = await client.service('users').create({ email, password })
      console.log('New user created:', newUser)

      const auth = await client.authenticate({
        strategy: 'local',
        email,
        password
      })
      console.log('Authentication successful:', auth)

      setUser(auth.user) // Set the user in the context
      setRole(auth.user.role || 'user')
      navigate('/')
    } catch (err: any) {
      console.error('Signup error:', err)
      if (err.name === 'NotAuthenticated') {
        setError('Failed to log in after signup. Please try logging in manually.')
      } else if (err.message === 'Email already exists') {
        setError(
          'This email is already registered. Please use a different email or try logging in.'
        )
      } else {
        setError(`Failed to sign up: ${err.message}`)
      }
    }
  }

  return (
    <div className="max-w-md mx-auto mt-10">
      <h1 className="text-3xl font-bold mb-6 text-center text-green-600">Sign Up</h1>
      <form onSubmit={handleSubmit} className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
        {error && <div className="mb-4 text-red-500 text-center">{error}</div>}
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
            Email
          </label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="email"
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="mb-6">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="password">
            Password
          </label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
            id="password"
            type="password"
            placeholder="******************"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <div className="flex items-center justify-between">
          <button
            className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            type="submit"
          >
            Sign Up
          </button>
        </div>
      </form>
    </div>
  )
}

export default SignupView
