import React, { useState } from 'react'
import { PencilIcon } from '@heroicons/react/24/solid'

interface EditableFieldProps {
  label: string
  value: string
  onSave: (value: string) => Promise<string | null>
  editable?: boolean // Add this line
  labelClassName?: string
  valueClassName?: string
  inputClassName?: string
  buttonClassName?: string
  errorClassName?: string
}

const EditableField: React.FC<EditableFieldProps> = ({
  label,
  value,
  onSave,
  editable = true,
  labelClassName = 'block text-xs font-medium text-gray-900',
  valueClassName = 'text-sm text-gray-900',
  inputClassName = 'focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-none rounded-l-md sm:text-sm border-gray-300 border-r-0',
  buttonClassName = 'inline-flex items-center px-2 py-1 text-sm leading-4 font-medium rounded-r-md text-white bg-gray-700 border-gray-300 border-l-0',
  errorClassName = 'mt-2 text-sm text-red-600'
}) => {
  const [isEditing, setIsEditing] = useState(false)
  const [editedValue, setEditedValue] = useState(value)
  const [error, setError] = useState<string | null>(null)

  const handleSave = async () => {
    if (editedValue !== value) {
      const result = await onSave(editedValue)
      if (result !== null) {
        setIsEditing(false)
      }
    } else {
      setIsEditing(false)
    }
  }

  if (!editable) {
    return (
      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2">{label}</label>
        <p className="text-gray-900">{value}</p>
      </div>
    )
  }

  return (
    <div className="mb-4">
      <label className="block text-gray-700 text-xs font-bold mb-2">{label}</label>
      {isEditing ? (
        <div className="flex">
          <input
            type="text"
            value={editedValue}
            onChange={(e) => setEditedValue(e.target.value)}
            className={inputClassName}
          />
          <button onClick={handleSave} className={buttonClassName}>
            Save
          </button>
          <button
            onClick={() => {
              setIsEditing(false)
              setEditedValue(value)
            }}
            className="ml-2 inline-flex items-center px-2 py-1 border border-gray-300 shadow-sm text-xs leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
          >
            Cancel
          </button>
        </div>
      ) : (
        <div className="flex justify-between items-center">
          <p className={valueClassName}>{value}</p>
          <button
            onClick={() => setIsEditing(true)}
            className="inline-flex items-center p-2 py-1 ml-2 text-xs rounded-lg text-blue-600 bg-white hover:border hover:border-gray-300 hover:shadow-sm"
          >
            Edit
          </button>
        </div>
      )}
      {error && <p className={errorClassName}>{error}</p>}
    </div>
  )
}

export default EditableField
