import React from 'react'
import { Link } from 'react-router-dom'
import { useUser } from '../contexts/UserContext'

const NavBar: React.FC = () => {
  const { user, role, logout, isLoading } = useUser()

  if (isLoading) {
    return (
      <nav className="bg-amber-300 text-gray-700">
        <div className="container mx-auto px-4 py-2">
          <div className="flex justify-between items-center">
            <div className="flex items-center space-x-4">
              <Link to="/" className="text-md font-bold">
                lemons
              </Link>
            </div>
            <div className="text-xs font-semibold">Loading...</div>
          </div>
        </div>
      </nav>
    )
  }

  return (
    <nav className="bg-amber-300 text-gray-700">
      <div className="container mx-auto px-4 py-2">
        <div className="flex justify-between items-center">
          <div className="flex items-center space-x-4">
            <Link to="/" className="text-md font-bold">
              lemons
            </Link>
          </div>
          <ul className="flex space-x-4 text-xs font-semibold">
            {/* {!user && (
              <>
                <li>
                  <Link to="/login" className="hover:text-green-200">
                    Login
                  </Link>
                </li>
                <li>
                  <Link to="/signup" className="hover:text-green-200">
                    Sign Up
                  </Link>
                </li>
              </>
            )}
            {user && (
              <>
                {role === 'admin' && (
                  <li>
                    <Link to="/admin" className="hover:text-green-200">
                      <div className="flex items-center h-full">Admin Panel</div>
                    </Link>
                  </li>
                )}
                <li>
                  <Link to={`/profile/${user.username}`} className="hover:text-green-200">
                    <div className="flex items-center h-full">Profile</div>
                  </Link>
                </li>
                <li>
                  <button
                    onClick={logout}
                    className="hover:text-green-200 bg-green-700 px-3 py-1 rounded"
                  >
                    Logout
                  </button>
                </li>
              </>
            )} */}
          </ul>
        </div>
      </div>
    </nav>
  )
}

export default NavBar
