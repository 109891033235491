import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'

interface Game {
  _id: string
  title: string
  dateTimeOptions: Date[]
  confirmedDateTime?: Date
  location: string
  gameType: string
  status: 'pending' | 'confirmed' | 'completed' | 'cancelled'
  participants: string[]
  organizer: {
    _id: string
    username: string
  }
}

interface Invitation {
  _id: string
  inviteeEmail: string
  status: 'pending' | 'accepted' | 'declined' | 'suggested_alternative'
}

// Mock data
const mockGames: Record<string, Game> = {
  '1': {
    _id: '1',
    title: 'Weekend Golf Tournament',
    dateTimeOptions: [new Date('2023-06-10T09:00:00'), new Date('2023-06-11T10:00:00')],
    location: 'Sunny Hills Golf Club',
    gameType: 'Tournament',
    status: 'pending',
    participants: ['user1', 'user2', 'user3'],
    organizer: { _id: 'user1', username: 'JohnDoe' }
  },
  '2': {
    _id: '2',
    title: 'Casual Round with Friends',
    dateTimeOptions: [new Date('2023-06-15T14:00:00')],
    confirmedDateTime: new Date('2023-06-15T14:00:00'),
    location: 'Green Meadows Golf Course',
    gameType: 'Casual Round',
    status: 'confirmed',
    participants: ['user1', 'user4', 'user5'],
    organizer: { _id: 'user1', username: 'JohnDoe' }
  }
}

const mockInvitations: Record<string, Invitation[]> = {
  '1': [
    { _id: 'inv1', inviteeEmail: 'user2@example.com', status: 'accepted' },
    { _id: 'inv2', inviteeEmail: 'user3@example.com', status: 'pending' },
    { _id: 'inv3', inviteeEmail: 'user4@example.com', status: 'declined' }
  ],
  '2': [
    { _id: 'inv4', inviteeEmail: 'user4@example.com', status: 'accepted' },
    { _id: 'inv5', inviteeEmail: 'user5@example.com', status: 'accepted' }
  ]
}

const GameDetailsView: React.FC = () => {
  const [game, setGame] = useState<Game | null>(null)
  const [invitations, setInvitations] = useState<Invitation[]>([])
  const [loading, setLoading] = useState(true)
  const { id } = useParams<{ id: string }>()

  useEffect(() => {
    // Simulate API call with setTimeout
    setTimeout(() => {
      if (id && mockGames[id]) {
        setGame(mockGames[id])
        setInvitations(mockInvitations[id] || [])
      }
      setLoading(false)
    }, 1000)
  }, [id])

  if (loading) return <div className="text-center mt-8">Loading...</div>
  if (!game) return <div className="text-center mt-8">Game not found</div>

  return (
    <div className="max-w-4xl mx-auto mt-8 p-6 bg-white rounded-lg shadow-md">
      <h1 className="text-3xl font-bold mb-6">{game.title}</h1>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Game Details</h2>
        <p>Organizer: {game.organizer.username}</p>
        <p>Status: {game.status}</p>
        <p>Type: {game.gameType}</p>
        <p>Location: {game.location}</p>
        {game.confirmedDateTime ? (
          <p>Confirmed Date: {new Date(game.confirmedDateTime).toLocaleString()}</p>
        ) : (
          <div>
            <p>Proposed Dates:</p>
            <ul>
              {game.dateTimeOptions.map((date, index) => (
                <li key={index}>{new Date(date).toLocaleString()}</li>
              ))}
            </ul>
          </div>
        )}
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Participants</h2>
        <ul>
          {game.participants.map((participantId) => (
            <li key={participantId}>{participantId === 'user1' ? 'You' : participantId}</li>
          ))}
        </ul>
      </section>

      <section>
        <h2 className="text-2xl font-semibold mb-4">Invitations</h2>
        <ul>
          {invitations.map((invitation) => (
            <li key={invitation._id}>
              {invitation.inviteeEmail} - Status: {invitation.status}
            </li>
          ))}
        </ul>
      </section>
    </div>
  )
}

export default GameDetailsView
